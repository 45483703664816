var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h2',[_vm._v("Tours")])])],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.customer.tours,"no-data-text":"There are no tours for this customer"},scopedSlots:_vm._u([{key:"item.title",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'module-drum-proposals-individual',
          params: { tourId: item.id },
        }}},[_vm._v(_vm._s(item.title))])]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"label":"","small":"","color":item.status === 'tour' ? 'green' : 'orange',"text-color":"white"}},[_vm._v(_vm._s(item.status === "tour" ? "Confirmed" : "Proposal"))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
              name:
                item.status === 'Tour'
                  ? 'module-drum-tours-individual'
                  : 'module-drum-proposals-individual',
              params: { tourId: item.id },
            }}},on),[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }