<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h2>Tours</h2>
      </v-col>
    </v-row>
    <v-data-table
      :headers="tableHeaders"
      :items="customer.tours"
      no-data-text="There are no tours for this customer"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          :to="{
            name: 'module-drum-proposals-individual',
            params: { tourId: item.id },
          }"
          >{{ item.title }}</router-link
        >
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          label
          small
          :color="item.status === 'tour' ? 'green' : 'orange'"
          text-color="white"
          >{{ item.status === "tour" ? "Confirmed" : "Proposal" }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              depressed
              color="green lighten-4 green--text"
              v-on="on"
              :to="{
                name:
                  item.status === 'Tour'
                    ? 'module-drum-tours-individual'
                    : 'module-drum-proposals-individual',
                params: { tourId: item.id },
              }"
            >
              <v-icon x-small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>View</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableHeaders: [
        { text: "Title", value: "name" },
        { text: "Date", value: "formatted_dates.date" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },
};
</script>
